@media print {
  @page {
    size: A4 landscape;
    margin: 0;
    padding: 0;
  }

  .navbar, .fc-header-toolbar > .fc-toolbar-chunk:not(:nth-child(2)) {
    display: none !important;
  }

  .App .fc .fc-toolbar.fc-header-toolbar {
    justify-content: center;
    margin-bottom: 0;
    page-break-after: avoid;
  }

  .App > .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  table {
    page-break-after: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .fc-view-harness {
    width: 31.8cm !important;
  }

  .content-col {
    padding: 0;
  }

  .content-col.calendar-1 {
    page-break-after: always;
  }

  .fc-daygrid-day-top .day-cell-content-month-text {
    display: block
  }

  .fc .fc-daygrid-event {
    border: none;
    background: white;
  }   

  .fc-event-main .event-text-area {
    color: black;
    padding: 0.35em;
  }

  .calendar-1 .fc-toolbar-chunk .fc-toolbar-title {
    margin-left: 0;
  }
}
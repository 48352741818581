@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #dde9f1;
  min-width: fit-content;
}

.fc-media-screen, .navbar-brand {
  width: 31.8cm !important;
  margin: auto;
}

.navbar {
  box-shadow: 0px 0px 4px #6c757d;
}

.navbar-brand {
  font-size: 1.5em;
}

.fc-view-harness {
  width: 31.8cm !important;
  background-color: white;
}

.fc-today-button {
  text-transform: capitalize !important;
}

.fc .fc-toolbar-chunk .fc-toolbar-title {
  font-weight: 700;
  font-size: 2.5em;
}

.calendar-1 .fc-toolbar-chunk .fc-toolbar-title {
  margin-left: 2.625em;
}

.fc-col-header-cell-cushion, .fc-daygrid-day-number, .fc-daygrid-day-number:hover, .fc-col-header-cell-cushion:hover {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

table, .fc-theme-standard td, .fc-theme-standard th {
  border-color: black !important;
}

.App .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
}

.fc .fc-button.fc-button-primary {
  color: #3c4043;
  background-color: #ffffff;
}